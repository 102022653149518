import axios from 'axios'
import router from '@/router'
import { useToast } from 'vue-toast-notification'

const toaster = useToast()

let config = {
  baseURL: process.env.VUE_APP_BASE_URL + '/api',
  timeout: 15 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  // headers: {},
}

const _axios = axios.create(config)

export const setToken = (token) => {
  _axios.defaults.headers.Authorization = 'Bearer ' + token
}

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      router.push({ name: 'Login' })
    }
    if (error.response.status === 404) {
      router.push({ name: 'Page404' })
    }
    if (error.response.status === 422) {
      console.log(error.response.data.errors)
      toaster.error(error.response.data.message)
    }

    return Promise.reject(error)
  },
)

_axios.get('/sanctum/csrf-cookie', {
  baseURL: process.env.VUE_APP_BASE_URL,
})

export default _axios
