import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/deals/list',
    meta: {
      title: 'Главная',
    },
    children: [
      // {
      //   path: '/dashboard',
      //   name: 'Dashboard',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      // },
      {
        path: '/deals',
        name: 'deals',
        component: () => import('@/views/index.vue'),
        meta: {
          title: 'Сделки',
        },
        children: [
          {
            path: '',
            redirect: '/deals/list',
          },
          {
            path: 'list',
            name: 'deals.list',
            component: () => import('@/views/deals/index.vue'),
            meta: {
              title: 'Список',
            },
          },
          {
            path: ':id(\\d+)/edit',
            name: 'deals.edit',
            props: true,
            component: () => import('@/views/deals/edit.vue'),
            meta: {
              title: 'Редактировать',
            },
          },
          {
            path: 'categories',
            name: 'categories',
            component: () => import('@/views/deals/categories/index.vue'),
            meta: {
              title: 'Категории',
            },
          },
          {
            path: 'add',
            name: 'categories.add',
            component: () => import('@/views/deals/categories/add.vue'),
            meta: {
              title: 'Добавить',
            },
          },
          {
            path: 'categories/:id(\\d+)/edit',
            name: 'categories.edit',
            props: true,
            component: () => import('@/views/deals/categories/edit.vue'),
            meta: {
              title: 'Редактировать',
            },
          },
        ],
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/index.vue'),
        meta: {
          title: 'Клиенты',
        },
        children: [
          {
            path: '',
            name: 'customers.list',
            component: () => import('@/views/customers/index.vue'),
            meta: {
              title: 'Список',
            },
          },
          {
            path: 'add',
            name: 'customers.add',
            component: () => import('@/views/customers/add.vue'),
            meta: {
              title: 'Добавить',
            },
          },
          {
            path: ':id(\\d+)/edit',
            name: 'customers.edit',
            props: true,
            component: () => import('@/views/customers/edit.vue'),
            meta: {
              title: 'Редактировать',
            },
          },
        ],
      },
      {
        path: '/inventory',
        name: 'inventory',
        component: () => import('@/views/index.vue'),
        meta: {
          title: 'Инвентарь',
        },
        children: [
          {
            path: '',
            name: 'inventory.list',
            component: () => import('@/views/inventory/index.vue'),
            meta: {
              title: 'Список',
            },
          },
          {
            path: 'add',
            name: 'inventory.add',
            component: () => import('@/views/inventory/add.vue'),
            meta: {
              title: 'Добавить',
            },
          },
          {
            path: ':id(\\d+)/edit',
            name: 'inventory.edit',
            props: true,
            component: () => import('@/views/inventory/edit.vue'),
            meta: {
              title: 'Редактировать',
            },
          },
        ],
      },
      {
        path: '/products',
        name: 'products',
        component: () => import('@/views/index.vue'),
        meta: {
          title: 'Товары',
        },
        children: [
          {
            path: '',
            redirect: '/products/list',
          },
          {
            path: 'list',
            name: 'products.list',
            component: () => import('@/views/products/index.vue'),
            meta: {
              title: 'Список',
            },
          },
          {
            path: 'add',
            name: 'products.add',
            component: () => import('@/views/products/add.vue'),
            meta: {
              title: 'Добавить',
            },
          },
          {
            path: ':id(\\d+)/edit',
            name: 'products.edit',
            props: true,
            component: () => import('@/views/products/edit.vue'),
            meta: {
              title: 'Редактировать',
            },
          },
          {
            path: 'categories',
            name: 'product.categories',
            component: () => import('@/views/products/categories/index.vue'),
            meta: {
              title: 'Категории',
            },
          },
          {
            path: 'categories/add',
            name: 'product.category.add',
            component: () => import('@/views/products/categories/add.vue'),
            meta: {
              title: 'Добавить категорию',
            },
          },
          {
            path: 'categories/:id(\\d+)/edit',
            name: 'product.category.edit',
            props: true,
            component: () => import('@/views/products/categories/edit.vue'),
            meta: {
              title: 'Редактировать категорию',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      need_auth: false,
    },
    component: () => import('@/views/login.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
