import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import _axios, { setToken } from '@/plugins/axios'
import qs from 'qs'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    is_authorised: false,
    dealCategories: [],
    productCategories: [],
    customers: [],
    products: [],
    deals: [],
    inventories: [],
    access_token: '',
    user: {},
  },
  getters: {
    is_authorised: (state) => state.is_authorised,
    access_token: (state) => state.access_token,
    dealCategories: (state) => state.dealCategories,
    productCategories: (state) => state.productCategories,
    customers: (state) => state.customers,
    products: (state) => state.products,
    deals: (state) => state.deals,
    inventories: (state) => state.inventories,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setDealCategories(state, data) {
      state.dealCategories = data
    },
    setProductCategories(state, data) {
      state.productCategories = data
    },
    setCustomers(state, data) {
      state.customers = data
    },
    setProducts(state, data) {
      state.products = data
    },
    setDeals(state, data) {
      state.deals = data
    },
    setInventories(state, data) {
      state.inventories = data
    },
    login(state, { token, user }) {
      state.is_authorised = true
      state.access_token = token
      state.user = user
    },
    LOG_OUT(state) {
      state.is_authorised = false
      state.access_token = ''
      state.refresh_token = ''
    },
  },
  actions: {
    getDealCategories({ commit }) {
      _axios
        .get('/categories', { params: { type: 'deal' } })
        .then(({ data }) => {
          commit('setDealCategories', data.data)
        })
    },
    getProductCategories({ commit }) {
      _axios
        .get('/categories', { params: { type: 'product' } })
        .then(({ data }) => {
          commit('setProductCategories', data.data)
        })
    },
    getCustomers({ commit }, { page, perPage, filter }) {
      _axios
        .get('/customers?' + qs.stringify({ page, per_page: perPage, filter }))
        .then(({ data }) => {
          commit('setCustomers', data)
        })
    },
    getProducts({ commit }, { page, perPage, filter }) {
      _axios
        .get('/products?' + qs.stringify({ page, per_page: perPage, filter }))
        .then(({ data }) => {
          commit('setProducts', data)
        })
    },
    getDeals({ commit }, { page, perPage, filter }) {
      _axios
        .get('/deals?' + qs.stringify({ page, per_page: perPage, filter }))
        .then(({ data }) => {
          commit('setDeals', data)
        })
    },
    getInventories({ commit }, { page, perPage, filter }) {
      _axios
        .get(
          '/inventories?' + qs.stringify({ page, per_page: perPage, filter }),
        )
        .then(({ data }) => {
          commit('setInventories', data)
        })
    },
    login: async ({ commit }, { email, password }) => {
      let response = await _axios.post('login', {
        email,
        password,
        remember: true,
      })
      if (
        response.data.status_code === 200 &&
        response.data.data.access_token
      ) {
        const token = response.data.data.access_token
        commit('login', {
          token,
          user: response.data.data.user,
        })
        setToken(token)
        return true
      }
      return false
    },
    logout: ({ commit }) => {
      commit('LOG_OUT')
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: 'frog-admin',
    }),
  ],
})
