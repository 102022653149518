import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { _axios, setToken } from '@/plugins/axios'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ru from 'element-plus/dist/locale/ru'

if (store.getters.access_token) {
  setToken(store.getters.access_token)
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueToast)
app.use(CoreuiVue)
app.use(_axios)
app.use(ElementPlus, {
  locale: ru,
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
