<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav>
        <CNavItem>
          <CHeaderToggler @click="btnLogOut">
            <CIcon class="mx-2" icon="cil-account-logout" size="lg" />
            <span>Выйти</span>
          </CHeaderToggler>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script setup>
import AppBreadcrumb from './AppBreadcrumb'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const btnLogOut = () => {
  store.dispatch('logout')
  router.push({ name: 'Login' })
}
</script>
