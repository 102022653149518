export default [
  // {
  //   component: 'CNavItem',
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: 'cil-speedometer',
  //   badge: {
  //     color: 'primary',
  //     text: 'NEW',
  //   },
  // },
  {
    component: 'CNavTitle',
    name: 'Сделки',
  },
  {
    component: 'CNavItem',
    name: 'Список',
    to: '/deals/list',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Категории',
    to: '/deals/categories',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavTitle',
    name: 'База',
  },
  {
    component: 'CNavItem',
    name: 'Клиенты',
    to: '/customers',
    icon: 'cil-user',
  },
  {
    component: 'CNavTitle',
    name: 'Инвентарь',
  },
  {
    component: 'CNavItem',
    name: 'Список',
    to: '/inventory',
    icon: 'cil-list',
  },
  {
    component: 'CNavTitle',
    name: 'Товары',
  },
  {
    component: 'CNavItem',
    name: 'Список',
    to: '/products/list',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Категории',
    to: '/products/categories',
    icon: 'cil-pencil',
  },
]
