<template>
  <CFooter>
    <div>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ms-auto">
      <span class="me-1">Rent</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
